import React from 'react';
import '../styles/krankenkassen.scss';

const Krankenkassen = () => {
  return (
    <section id="krankenkassen">
      <div>
        <h2>Krankenkassen</h2>
        <p>
          Wir sind Wahlarzt für alle österreichischen Gesundheitskassen (ÖGK)!
        </p>

        <p>
          Wahlarztpatienten bekommen eine Rechnung ausgehändigt und können diese
          dann bei ihrer zuständigen Krankenversicherung einreichen.
        </p>

        <p>
          Es wird Ihnen ein bestimmter Anteil von der Versicherung
          zurückerstattet.
        </p>

        <p>Näheres erklären wir Ihnen gerne im persönlichen Gespräch.</p>
      </div>
      <div className="right">
        <p>Unsere Ordination hat Verträge mit folgenden Kassen:</p>
        <ul>
          <li>
            <b>SVS-GW:</b> Sozialversicherungsanstalt der Selbständigen −
            gewerbliche Wirtschaft
          </li>
          <li>
            <b>SVS-LW:</b> Sozialversicherungsanstalt der Selbständigen −
            Landwirtschaft
          </li>
          <li>
            <b>BVAEB:</b> Sozialversicherungsanstalt der öffentlich
            Bediensteten, Eisenbahnen und Bergbau
          </li>
          <li>
            <b>KFA:</b> Krankenfürsorgeanstalt der Bediensteten der Stadt Wien
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Krankenkassen;
