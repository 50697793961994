import React, { useEffect, useState } from 'react';
import '../styles/termine.scss';
import { firestore } from '../firebase';

const Termine = () => {
  const textRef = firestore.collection('messages').doc('urlaub');
  const [text, setText] = useState('');

  useEffect(() => {
    textRef.get().then((doc) => {
      if (doc.exists) {
        setText(doc.data().text);
      }
    });
  }, [textRef]);

  return (
    <section id="termine">
      <div className="zeiten">
        <h2>Ordinationszeiten</h2>
        <table>
          <tbody>
            <tr>
              <th>Montag</th>
              <td>08:00 - 14:00</td>
            </tr>
            <tr>
              <th>Dienstag</th>
              <td>08:00 - 14:00</td>
            </tr>
            <tr>
              <th>Mittwoch</th>
              <td>
                <em>Keine Ordination</em>
              </td>
            </tr>
            <tr>
              <th>Donnerstag</th>
              <td>08:00 - 14:00</td>
            </tr>
            <tr>
              <th>Freitag</th>
              <td>08:00 - 14:00</td>
            </tr>
          </tbody>
        </table>
        <p>
          <em>
            Für eine schnelle Terminvereinbarung bitten wir Sie,{' '}
            <a style={{ color: 'unset' }} href="tel:+4321672592">
              telefonisch
            </a>{' '}
            mit uns Kontakt aufzunehmen!
          </em>
        </p>
      </div>
      <div className="urlaub">
        <h2>Urlaub</h2>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default Termine;
