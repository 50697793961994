import React, { lazy, Suspense } from 'react';
import '../styles/team.scss';
const Members = lazy(() => import('./Members'));

const Team = () => {
  return (
    <section id="team">
      <h2>Unser Team</h2>

      <Suspense fallback={<div>Loading Team....</div>}>
        <Members />
      </Suspense>
    </section>
  );
};

export default Team;
