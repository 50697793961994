import React from 'react';
import '../styles/footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="footer">
      <span> © Dr. Heinz Sattler, 2020</span>
      <ul>
        <li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/impressum">
            Impressum
          </Link>
        </li>
        <li>
          <Link onClick={() => window.scrollTo(0, 0)} to="/datenschutz">
            Datenschutz
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
