import React, { lazy, Suspense } from 'react';
import '../styles/kontakt.scss';
import addressIcon from '../graphics/address_icon.svg';
import phoneIcon from '../graphics/phone_icon.svg';
//import Karte from './Karte';
const Karte = lazy(() => import('./Karte'));

const Kontakt = () => {
  return (
    <section id="kontakt">
      <div className="kontakt-info">
        <h2>Wie Sie uns erreichen</h2>
        <div className="adress-info">
          <img alt="adresse symbol" src={addressIcon} />
          <div className="adresse">
            <span>Hauptplatz 47</span>
            <span>7100 Neusiedl am See</span>
            <span>Österreich</span>
          </div>
        </div>
        <div className="phone-number">
          <img alt="telefon symbol" src={phoneIcon} />
          <span>
            <a
              style={{ color: 'unset', textDecoration: 'none' }}
              href="tel:+4321672592"
            >
              +43 (0)2167 2592
            </a>
          </span>
        </div>
      </div>
      <div className="karte">
        <Suspense fallback={<div>Loading...</div>}>
          <Karte />
        </Suspense>
      </div>
    </section>
  );
};

export default Kontakt;
