import React, { useEffect } from 'react';
import '../styles/impressum.scss';

const Impressum = () => {
  useEffect(() => {
    document.getElementById('footer').classList.add('white-bg-footer');

    return () =>
      document.getElementById('footer').classList.remove('white-bg-footer');
  }, []);
  return (
    <section id="impressum">
      <div>
        <h2>Impressum</h2>

        <h3>Information Leistungen der ärztlichen Ordination</h3>

        <p>
          Information gemäß § E-Commerce-Gesetz und Offenlegung gemäß § 25
          Mediengesetz
        </p>

        <section>
          <h4>Inhaber:</h4>
          Dr. Heinz Sattler <br />
          Facharzt für Innere Medizin
          <h4>Anschrift:</h4>
          Hauptplatz 47 <br />
          7100 Neusield am See <br />
          Österreich
          <h4>Telefon:</h4> +43 (0)2167 2592
          <h4>E-Mail:</h4> heinzsattler@aon.at
          <h4>Bildnachweis:</h4>Michael Himml <br />
          <a href="http://www.michaelhimml.at">
            http://www.michaelhimml.at
          </a>{' '}
          <h4>Gestaltung und technische Umsetzung:</h4> Michael Sattler <br />
          <a href="https://www.michaelsattler.at">
            https://www.michaelsattler.at
          </a>
        </section>
      </div>

      <div>
        <h3>Berufsbezeichnung:</h3> Facharzt für Innere Medizin (verliehen in
        Österreich)
        <p>
          Mitglied der Ärztekammer für Burgenland Tätigkeit unterliegt dem
          Ärztegesetz 1998 (siehe{' '}
          <a href="http://www.ris.bka.gv.at/bundesrecht/">
            http://www.ris.bka.gv.at/bundesrecht/
          </a>
          )
        </p>
        <p>
          Bezirkshauptmannschaft Neusiedl am See UID-Nr.: ATU 56907379 Fehler
          und Irrtümer vorbehalten. Alle Angaben auf dieser Website ohne Gewähr.
        </p>
        <p>
          Haftungsansprüche jeglicher Art aufgrund der vorliegenden Inhalte sind
          ausgeschlossen. Ebenso wird für externe Links keine Haftung
          übernommen.
        </p>
        <p>
          Für den Inhalt der verlinkten Seiten sind ausschließlich deren
          Betreiber verantwortlich.
        </p>
      </div>
    </section>
  );
};

export default Impressum;
