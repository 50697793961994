import React from 'react';
import Flicking from '@egjs/react-flicking';
import { Fade, AutoPlay } from '@egjs/flicking-plugins';
import '../styles/gallerie.scss';
import GalleriePics from './GalleriePics';

const Gallerie = () => {
  const plugins = [new Fade(), new AutoPlay(3000, 'NEXT')];

  return (
    <section className="gallerie hanger">
      <Flicking
        gap={10}
        className="wrapper"
        hanger={'50%'}
        anchor={'50%'}
        circular={true}
        plugins={plugins}
        inputType={['touch', 'mouse']}
        autoResize={true}
        zIndex={0}
        duration={500}
      >
        <GalleriePics />
      </Flicking>
    </section>
  );
};

export default Gallerie;
