import React, { useState } from 'react';
import logo from '../graphics/logo.svg';
import hamburger from '../graphics/ham.svg';
import exit from '../graphics/exit_icon.svg';

import Scrollspy from 'react-scrollspy';
import '../styles/nav-bar.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [icon, setIcon] = useState(hamburger);

  let location = useLocation();
  const history = useHistory();
  const toggleNav = () => {
    document.getElementById('mobile-nav').classList.toggle('visible');
    setIcon((i) => (i === hamburger ? exit : hamburger));
  };

  const handleScroll = (e) => {
    if (e) {
      history.replace(`#${e.id}`);
    }
  };

  const handleLink = (e) => {
    history.replace('/');
  };

  const closeNav = () => {
    document.getElementById('mobile-nav').classList.remove('visible');
    setIcon(hamburger);
  };

  const items = [
    'home',
    'kontakt',
    'termine',
    'krankenkassen',
    'leistungen',
    'team',
  ];

  const renderItems = () => {
    const listItems = items.slice(1);
    return listItems.map((item, idx) => {
      const capitalizedItem = item[0].toUpperCase() + item.substr(1);
      return (
        <li onClick={closeNav} key={idx}>
          <a onClick={handleLink} href={`#${item}`}>
            {capitalizedItem}
          </a>
        </li>
      );
    });
  };

  const mainPageList = (
    <Scrollspy
      offset={-81}
      className="desktop-only"
      items={items}
      currentClassName="is-current"
      onUpdate={handleScroll}
    >
      <li></li>
      {renderItems()}
    </Scrollspy>
  );

  const altList = (
    <ul>
      <li></li>
      {renderItems()}
    </ul>
  );

  return (
    <React.Fragment>
      <nav>
        <header onClick={closeNav}>
          <Link onClick={() => window.scrollTo(0, 0)} to="/">
            <img src={logo} alt="logo" />
            <h2>
              Ordination <br />
              Dr. Heinz Sattler
            </h2>
          </Link>
        </header>
        <img
          onClick={toggleNav}
          className="mobile-only"
          src={icon}
          alt={icon === hamburger ? 'navigation icon' : 'exit icon'}
        />
        {location.pathname === '/' ? mainPageList : altList}
      </nav>
      <nav id="mobile-nav" className="mobile-only">
        {location.pathname === '/' ? mainPageList : altList}
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
