import * as firebase from 'firebase/app';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: 'AIzaSyAS-ncKJs-yI3095Ib_QrF1A4fvdU8KtRU',
  authDomain: 'ordination-sattler-b3c33.firebaseapp.com',
  databaseURL: 'https://ordination-sattler-b3c33.firebaseio.com',
  projectId: 'ordination-sattler-b3c33',
  storageBucket: 'ordination-sattler-b3c33.appspot.com',
  messagingSenderId: '157504617050',
  appId: '1:157504617050:web:4d66330849504f61bab663',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

export { firestore };
