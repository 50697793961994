import React from 'react';
import '../styles/leistungen.scss';

const Leistungen = () => {
  return (
    <section id="leistungen">
      <div>
        <h2>Unsere Leistungen</h2>
        <ul>
          <li>
            <b>Vorsorgeuntersuchung</b>
          </li>
          <li>
            <b>Echokardiographie</b> (Herzultraschall)
          </li>
          <li>
            <b>Blutgefäße</b> (Ultraschalluntersuchung von Venen & Arterien)
          </li>
          <li>
            <b>24h Blutdruckmessung</b>
          </li>
          <li>
            <b>EKG</b> (Elektrokardiogramm)
          </li>
          <li>
            <b>Langzeit EKG</b>
          </li>
          <li>
            <b>Ergometrie</b> (Belastungs EKG)
          </li>
          <li>
            <b>Spirometrie</b> (Lungenfunktionstest)
          </li>
          <li>
            <b>Labordiagnostik</b> mit entsprechender Interpretation
          </li>
        </ul>
      </div>
      <div className="right">
        <ul>
          <li>
            <b>OP-Freigaben</b>
          </li>
          <li>
            <b>Sonographie</b> (Ultraschalluntersuchung)
            <ul>
              <li>Schilddrüse</li>
              <li>Carotis (Halsschlagader)</li>
              <li>
                gesamter Bauchraum (Galle, Leber, Nieren, Bauchspeicheldrüse,
                Milz, etc.)
              </li>
            </ul>
          </li>

          <li>
            <b>Sanfte Endoskopie:</b>
            <ul>
              {' '}
              <li>Gastroskopie (Magenspiegelung)</li>
              <li>Koloskopie (Dickdarmspiegelung)</li>
              <li>Vorsorge Dickdarmspiegelung</li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Leistungen;
