import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Gallerie from './components/Gallerie';
import Text from './components/Text';
import Kontakt from './components/Kontakt';
import Termine from './components/Termine';
import Krankenkassen from './components/Krankenkassen';
import Leistungen from './components/Leistungen';
import Team from './components/Team';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Switch>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/datenschutz">
            <Datenschutz />
          </Route>

          <Route exact path="/">
            <main>
              <div id="home">
                <Gallerie />
              </div>
              <Text />

              <Kontakt />
              <Termine />
              <Krankenkassen />
              <Leistungen />
              <Team />
            </main>
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
