import React from 'react';
import '../styles/text.scss';

const Text = () => {
  return (
    <section id="text">
      <h1>Ihr Facharzt für Innere Medizin</h1>
      <p>
        Herzlich Willkommen auf unserer Website. Unser Team ist stets bemüht
        Ihnen bei Ihrem Besuch die bestmögliche Behandlung und eine individuell
        abgestimmte Beratung zu Gesundheitsthemen wie Diabetes, Bluthochdruck
        oder Magen/Darmerkrankungen zu bieten. Im Vordergrund steht dabei
        natürlich immer Ihre Gesundheit, dafür nehmen wir uns gerne Zeit.
      </p>
    </section>
  );
};

export default Text;
