import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/datenschutz.scss';

const Datenschutz = () => {
  useEffect(() => {
    document.getElementById('footer').classList.add('white-bg-footer');

    return () =>
      document.getElementById('footer').classList.remove('white-bg-footer');
  }, []);
  return (
    <section id="datenschutz">
      <h2>Datenschutz</h2>
      <h3>Erklärung zur Informationspflicht</h3>{' '}
      <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
        verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
        gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
        Datenschutzinformationen informieren wir Sie über die wichtigsten
        Aspekte der Datenverarbeitung im Rahmen unserer Website.
      </p>
      <h3>Kontakt mit uns</h3>{' '}
      <p>
        Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns
        aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage
        und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert.
        Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Ihre Rechte
        Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
        Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch
        zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren. In Österreich ist dies die
        Datenschutzbehörde.
      </p>{' '}
      <h3>Server-Log-Dateien</h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen
        Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a
        (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.
      </p>{' '}
      <h3>Google Fonts</h3>{' '}
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in Ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem
        Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
        von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass
        über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
        Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn
        Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
        Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden
        Sie unter{' '}
        <a href="https://developers.google.com/fonts/">
          https://developers.google.com/fonts/faq
        </a>{' '}
        und in der Datenschutzerklärung von Google:{' '}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        .
      </p>
      <p>
        Sie erreichen uns unter den im <Link to="/impressum">Impressum</Link>{' '}
        genannten Kontaktdaten
      </p>
    </section>
  );
};

export default Datenschutz;
