import React, { Fragment } from 'react';
import ordi001 from '../images/ordi001.jpg';
import ordi002 from '../images/ordi002.jpg';
import ordi004 from '../images/ordi004.jpg';
import ordi005 from '../images/ordi005.jpg';
import team from '../images/team.jpg';
import heinzGallerie from '../images/heinzGallerie.jpg';

const GalleriePics = () => {
  const pics = [
    { img: heinzGallerie, alt: 'Dr. Heinz Sattler' },
    { img: ordi001, alt: 'Warteraum' },
    { img: team, alt: 'Team der Ordination Dr. Sattler' },
    { img: ordi002, alt: 'Endoskopiezimmer' },
    { img: ordi005, alt: 'Dr. Sattler im Patientengespräch' },
    { img: ordi004, alt: 'Ultraschall und Sprechzimmer' },
  ];
  return (
    <Fragment>
      {' '}
      {pics.map((pic, idx) => (
        <div key={idx} className="panel">
          <img className="anchor" alt={pic.alt} src={pic.img} />
        </div>
      ))}
    </Fragment>
  );
};

export default GalleriePics;
